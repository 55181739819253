import * as React from "react";
import { MenuItemLink, Responsive, usePermissions } from "react-admin";
import KPIsIcon from "@material-ui/icons/Book";
import LabelIcon from "@material-ui/icons/Label";
import IsoIcon from "@material-ui/icons/Iso";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import SpeedIcon from "@material-ui/icons/Speed";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import ProductTypeIcon from "@material-ui/icons/LocalOffer";
import FunctionsIcon from "@material-ui/icons/Functions";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SLAsIcon from "@material-ui/icons/HowToReg";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import Logo from "../../imports/alfuttaim.png";
import { resources } from "../../provider/configs";
import { authProvider } from "../../provider/provider";
import { getParameterByName } from "../../helpers/utils";

const menuItemsGenerator = (permissions) => {
  const isAllowed = (name, action = "find") => authProvider.isAllowed(permissions, name, action);

  let items = [
    isAllowed(resources.dashboard.app.name, resources.dashboard.app.find)
      ? { item: "dashboard", icon: <DashboardIcon /> }
      : null,
    isAllowed(resources.quality.app.name, resources.quality.app.find)
      ? { item: "quality", icon: <ProductTypeIcon /> }
      : null,
    isAllowed(resources.slas.app.name, resources.slas.app.find) ? { item: "slas", icon: <SLAsIcon /> } : null,
    isAllowed(resources.kpis.app.name, resources.kpis.app.find) ? { item: "kpis", icon: <KPIsIcon /> } : null,
    isAllowed(resources.ncrs.app.name, resources.ncrs.app.find) ? { item: "ncrs", icon: <LabelIcon /> } : null,
    isAllowed(resources.oers.app.name, resources.oers.app.find) ? { item: "oers", icon: <IsoIcon /> } : null,
    isAllowed(resources.sppvs.app.name, resources.sppvs.app.find) ? { item: "sppvs", icon: <TrendingUpIcon /> } : null,
    isAllowed(resources.fmReviews.app.name, resources.fmReviews.app.find) ? { item: "fmReviews", icon: <SpellcheckIcon /> } : null,
    isAllowed(resources.opms.app.name, resources.opms.app.find) ? { item: "opms", icon: <SpeedIcon /> } : null,
    isAllowed(resources.regulatoryCompliance.app.name, resources.regulatoryCompliance.app.find)
      ? { item: "regulatoryCompliance", icon: <SpeedIcon /> }
      : null,
    isAllowed(resources.qaas.app.name, resources.qaas.app.find) ? { item: "qaas", icon: <LocationSearchingIcon /> } : null,
    isAllowed(resources.totals.app.name, resources.totals.app.find)
      ? { item: "totals", icon: <FunctionsIcon /> }
      : null,
    isAllowed(resources["prs-approvals"].app.name, resources["prs-approvals"].app.find)
      ? { item: "prs-approvals", icon: <CheckCircle /> }
      : null,
  ];
  return items.filter((e) => !!e);
};

const useStyles = makeStyles({
  selected: {
    backgroundColor: "blue",
    color: "white",
  },
  menu: {
    selected: {
      backgroundColor: "black",
      color: "green",
    },
  },
  menueItem: {
    fontSize: "small",
  },
});

const CustomMenue = ({ onMenuClick, logout }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const menuItems = menuItemsGenerator(permissions);

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  let urlQuery = JSON.parse(getParameterByName("filter") || "{}");
  let currentQuery = {};
  let queryExists = false;
  if (urlQuery.cost_center) {
    currentQuery.cost_center = urlQuery.cost_center;
    queryExists = true;
  }
  if (urlQuery.vendor) {
    currentQuery.vendor = urlQuery.vendor;
    queryExists = true;
  }
  if (urlQuery.year) {
    currentQuery.year = urlQuery.year;
    queryExists = true;
  }
  if (urlQuery.month) {
    currentQuery.month = urlQuery.month;
    queryExists = true;
  }

  return (
    <div className={classes.menu}>
      {open && (
        <p style={{ marginTop: "15px" }}>
          <img alt={"Alfuttaim"} style={{ display: "block", margin: "auto" }} src={Logo} />
        </p>
      )}
      {menuItems.map(({ item, icon }) => (
        <MenuItemLink
          key={resources[item].name}
          to={
            queryExists
              ? {
                  pathname: `/${resources[item].name}`,
                  search: `filter=${encodeURIComponent(JSON.stringify(currentQuery))}`,
                }
              : `/${resources[item].name}`
          }
          primaryText={resources[item].alias}
          leftIcon={icon}
          onClick={onMenuClick}
          color="primary"
          className={classes.menueItem}
        />
      ))}
      <Responsive small={logout} medium={null} />
    </div>
  );
};

export default CustomMenue;
